import React, { useEffect} from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { GreetingCard } from "./GreetingCard";
import { AmbHumTemp } from "./AmbHumTemp";
import { Location } from "./Location";
import { Health } from "./Health";
import { SideNavigation } from "../Sidebar/Navigation";

export const Home = () => {
	let navigate = useNavigate();
	useEffect(() => {
		const validated = Cookies.get("validated");
		if (!validated) {
			navigate("/");
		} 
	});
	return (
		<div className="dashboard d-flex">
			<div>
				<SideNavigation></SideNavigation>
			</div>
			<div
				style={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "column",
					height: "100vh",
					overflowY: "hidden",
				}}
			>
				<div style={{ height: "100%" }}>
					<div style={{ height: "100%", overflowY: "auto" }}>
						<div className="d-flex card-section-main">
							<div className="cards-container-main">
								<GreetingCard />
								<AmbHumTemp />
								<Location />
								<Health />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
